<template>
  <a-spin :spinning="spinning">
    <a-form
      :form="form"
      :layout="formLayout"
      class="adherent_form"
      id="add_form_form"
      name="add_form_form"
    >
      <h1 class="title">Paramètres :</h1>
      <a-row>
        <a-col :xs="22" :md="12">
          <a-form-item label="Logo:" v-bind="formItemLayout">
            <a-upload
              :action="uploadUrl()"
              :beforeUpload="beforeUpload"
              :headers="{ Authorization: `Bearer ${getAuthorizationToken()}` }"
              :multiple="false"
              :showUploadList="false"
              @change="handleChange"
              class="avatar-uploader"
              listType="picture-card"
              name="file"
            >
              <img
                :src="organisation.file_url"
                alt="avatar"
                class="profile_pic"
                v-if="organisation.file_name"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Joindre</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="Code:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Code est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Raison sociale:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'nom_fr',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nom  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Raison sociale [Ar]:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'nom_ar',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nom arabe est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Siege wilaya" v-bind="formItemLayout">
            <a-select
              @change="handleWilayasChange"
              placeholder="Choisir  une wilaya"
              v-decorator="[
                'siege_wilaya_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Siege wilaya  est obligatoire!',
                    },
                    {
                      validator: wilayaDeRegionDefaultValueIsnotPermited,
                    },
                  ],
                },
              ]"
            >
              <a-select-option :key="0">Choisir une wilaya</a-select-option>
              <a-select-option :key="wilaya.id" v-for="wilaya in listWilayas">
                {{ wilaya.nom_fr }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Siege commune" v-bind="formItemLayout">
            <a-select
              placeholder="Choisir  une commune"
              v-decorator="[
                'siege_commune_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Siege commune  est obligatoire!',
                    },
                    {
                      validator: communeDeRegionDefaultValueIsnotPermited,
                    },
                  ],
                },
              ]"
            >
              <a-select-option :key="0">Choisir une commune</a-select-option>
              <a-select-option
                :key="commune.id"
                v-for="commune in local_communes"
              >
                {{ commune.nom_fr }}
              </a-select-option>
            </a-select>
          </a-form-item>
                    <a-form-item label="Date création" v-bind="formItemLayout">
            <a-date-picker
              :disabled="isCreationDateDisabled"
              :format="dateFormat"
              style="width:100%"
              v-decorator="[
                'dt_creation',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Date  de création est obligatoire!',
                    },
                  ],
                },
              ]"
            />
                    </a-form-item>
            <a-form-item v-bind="formItemLayout" label="Les dates de paiement">
                <a-range-picker
                    v-decorator="['dt_paiement']"
                    style="width:100%"
                />
            </a-form-item>
            <a-form-item v-bind="formItemLayout" label="ID du store Aderial">
              <a-input v-decorator="['aderial_id']" />
            </a-form-item>
            <a-form-item v-bind="formItemLayout" label="Clé store Aderial">
              <a-input v-decorator="['aderial_key']" />
            </a-form-item>
            <a-form-item v-bind="formItemLayout" label="UUID store Aderial">
              <a-input v-decorator="['aderial_uuid']" />
            </a-form-item>
        </a-col>

        <a-col :xs="22" :md="12">
           <a-form-item label="Image d'accueil :" v-bind="formItemLayout">
            <a-upload
              :action="uploadUrl()"
              :beforeUpload="beforeUpload"
              :headers="{ Authorization: `Bearer ${getAuthorizationToken()}` }"
              :multiple="false"
              :showUploadList="false"
              @change="handleChange"
              class="avatar-uploader"
              listType="picture-card"
              name="login_bg_file"
            >
              <img
                :src="organisation.login_bg_file_url"
                alt="avatar"
                class="profile_pic"
                v-if="organisation.login_bg_file_name"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Joindre</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="Adresse:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'adresse',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Adresse  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
                    <a-form-item label="Longitude :" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'longitude',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Longitude  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
                    <a-form-item label="Latitude:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'latitude',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Latitude  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Tél.:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'tel_mobile',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Tél  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Tél. Fixe:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'tel_fixe',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Tél  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Fax:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'tel_fax',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Fax  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Email:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Email  est obligatoire!',
                    },
                    {
                      type: 'email',
                      message: 'E-mail! n\'est pas valide ',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="CCP:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'ccp',
                {
                  rules: [
                    {
                      required: false,
                      message: 'CCP  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Compte bancaire:" v-bind="formItemLayout">
            <a-input
              v-decorator="[
                'compte_bancaire',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Compte bancaire  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
            <a-form-item v-bind="{
          labelCol: {
            xs: { span: 12 },
            sm: { span: 12 },
          },
          wrapperCol: {
            xs: { span: 10 },
            sm: { span: 10 },
          },
        }" label="Paiement collectif [Cabinet de groupe]:">
                <a-switch
                    v-decorator="[
                'is_cabinet_group_paiement_collectif',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Ce champ est bligatoire!',
                    },
                  ],
                },
              ]"
                    :checked="organisation.is_cabinet_group_paiement_collectif"
                    @change="onCabinetGroupToggle"/>

            </a-form-item>
        </a-col>
      </a-row>

      <a-row class="mt-2">
        <a-col :offset="2" :span="3">
          <a-form-item v-bind="tailFormItemLayout">
              <a-button
                  :loading="processing"
                  htmlType="submit"
                  type="primary"
                  @click="handleFormSubmission"
              >Sauvergarder
              </a-button>
          </a-form-item>
        </a-col>
        <a-col :offset="1" :span="3">
          <a-form-item v-bind="tailFormItemLayout">
            <a-button @click="handleCancel" type="default">Annuler</a-button>
          </a-form-item>
        </a-col>
        <a-col :offset="1" :span="3">
          <a-spin :spinning="updatingUsersReglementprocessing">
          <a-form-item v-bind="tailFormItemLayout">
            <a-button @click="updateUsersReglement" type="default"
              >Mettre à jour les régléments</a-button
            >
          </a-form-item>
          </a-spin>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>
<script>
// const CABINET_GROUP_PAIEMENT_COLLECTIF = 1;
// const CABINET_GROUP_PAIEMENT_PRESIDENT = 2;
const formItemLayout = {
    labelCol: {
        xs: {span: 6},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 12},
        sm: {span: 12},
    },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 0,
    },
  },
};
const permissionsItemLayout = {
  labelCol: {
    xs: { span: 3 },
      sm: {span: 3},
  },
    wrapperCol: {
        xs: {span: 21},
        sm: {span: 21},
    },
};
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import constants from "@/const/const";
import auth from "@/services/auth";
import {get} from "lodash-es";


export default {
    name: "SorProfileEditor",
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    created() {
        this.getData()
            .then(() => {
        this.local_communes = this.communes;
        this.fillTheForm();
        this.spinning = false;
      })
      .catch(() => (this.spinning = false));
  },
  mounted() {
    this.url = this.base_url;
  },
  data() {
    return {
      formItemLayout,
      tailFormItemLayout,
      permissionsItemLayout,
      local_communes: [],
      url: null,
      loading: false,
      spinning: false,
      processing: false,
      updatingUsersReglementprocessing: false,
      selectedKeys: ["1", "4"],
      DONNES_SAUVGARDER: constants.DONNES_SAUVGARDER,
      ERROR_HAPENNED: constants.ERROR_HAPENNED,
        get
    };
  },
  computed: {
    ...mapState({
      base_url: (state) => state.auth.base_url,
      organisation: (state) => state.organisation.organisation,
      listWilayas: (state) => state.wilayas.organisation_wilayas,
      targetKeys: (state) => state.organisation.listWilayasKeys,
      wilayas: (state) => state.wilayas.wilayas,
      communes: (state) => state.wilayas.organisation_communes,
    }),
    isCreationDateDisabled() {
      return this.get(this.organisation, "dt_creation", null);
    },
  },
  methods: {
    async getData() {
      this.spinning = true;
      await this.$store.cache.dispatch("fetchOrganisation");
      await this.$store.cache.dispatch("fetchWilayas");
      await this.$store.cache.dispatch("fetchOrganisationCommunes");
      await this.$store.cache.dispatch("fetchOrganisationWilayas");
    },
    fillTheForm() {
        this.form.setFieldsValue(this.organisation);
        this.form.setFieldsValue({
            dt_creation: this.momentDate(this.organisation.dt_creation),
            dt_paiement: [
                this.momentDate(this.organisation.dt_paiement_du),
                this.momentDate(this.organisation.dt_paiement_au)
            ],
        });
    },
    updateUsersReglement() {
      this.updatingUsersReglementprocessing = true ;
        auth
            .axios()
            .get(`admin/remplir_etat_activite`)
            .then(function (response) {
                alert("Success");
            })
            .catch((er) => {
              // eslint-disable-next-line no-console
                console.error(er);
            }).finally(() => this.updatingUsersReglementprocessing = false);
    },
      handleWilayasChange(value) {
          this.form.setFieldsValue({siege_commune_id: 0});
          this.local_communes = this.communes.filter((item) => {
              return item.wilaya_id === value;
          });
      },
      handleCancel() {
          this.$router.go(-1)
      },
      onCabinetGroupToggle(checked) {
          this.organisation.is_cabinet_group_paiement_collectif = checked
      },
      handleFormSubmission() {
          const form = this.form;
          form.validateFieldsAndScroll((err, values) => {
              if (!err) {
                  values.dt_creation = this.formatDate(
                      values["dt_creation"],
                      "YYYY-MM-DD"
                  );
                  values.dt_paiement_du = this.formatDate(
                      values["dt_paiement"][0],
                      "YYYY-MM-DD"
                  );
                  values.dt_paiement_au = this.formatDate(
                      values["dt_paiement"][1],
                      "YYYY-MM-DD"
                  );

                  values.is_cabinet_group_paiement_collectif = this.organisation.is_cabinet_group_paiement_collectif;
                  this.processing = true;
                  this.update({
                      ...this.organisation,
                      ...values,
                  })
                      .then(() => {
                          this.fetchData();
                          this.processing = false;
                          this.$_showSuccessMessage(`${this.DONNES_SAUVGARDER}`);
                      })
                      .catch(() => {
              this.processing = false;
              this.$_throwAnError();
            });
        }
      });
    },
    uploadUrl() {
      return `${this.url}/admin/organisation/upload-logo`;
    },
    handleChange(info) {
      if (info.file.status !== "uploading") {
        this.loading = true;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.$_showSuccessMessage(
          `${info.file.name} ${constants.UPLOAD_SUCCESS_FICHIER}`
        );
        this.fetchData();
      }
      if (info.file.status === "error") {
        this.$_throwAnError(`${constants.UPLOAD_FAIL}`);
      }
    },
    beforeUpload(file) {
      const isImage =
        file.type === constants.JPEG_FILE || file.type === constants.PNG_FILE;
      if (!isImage) {
        this.$_throwAnError(`${constants.IMPORTATION_ERROR_TYPE}`);
      }
      return isImage;
    },
    wilayaDeRegionDefaultValueIsnotPermited(rule, value, callback) {
      const defaultValue = 0;
      let message =
        "Les deux mots de passe ne sont pas identiques !";
      this.checkDefaultValue(
        defaultValue,
        callback,
        this.form.getFieldValue("siege_wilaya_id"),
        message
      );
    },
    communeDeRegionDefaultValueIsnotPermited(rule, value, callback) {
      const defaultValue = 0;
      let message = "Commune est obligatoire!";
      this.checkDefaultValue(
        defaultValue,
        callback,
        this.form.getFieldValue("siege_commune_id"),
        message
      );
    },
    ...mapMutations(["setOrganisation", "setSorWilaysKeys"]),
    ...mapGetters(["getSorWilaysKeys"]),
    ...mapActions({
      update: "updateOrganisation",
    }),
  },
};
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.mt-2{
      margin-top: 2%;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-form-item-label {
  text-align: inherit !important;
}

.title {
  margin-bottom: 20px;
}

.profile_pic {
  width: 128px;
  height: 128px;
}
</style>
